import mutations from "./mutations.js";
import actions from "./actions.js";
import getters from "./getters.js";

export default {
  state() {
    return {
      swapDialog: {
        bool: false,
        DialnumAdd: [
          "0x82aF49447D8a07e3bd95BD0d56f35241523fBab1",
          "0xFd086bC7CD5C481DCC9C85ebE478A1C0b69FCbb9",
        ],
      },
      pathExists: true,
      watchInputs: [false, false],
      swapWatchInp: true,
      path: [
        "0x82aF49447D8a07e3bd95BD0d56f35241523fBab1",
        "0xFd086bC7CD5C481DCC9C85ebE478A1C0b69FCbb9",
      ],
      symbolsPath: ["WETH", "USDT"],
      swapTokenSymbol: ["WETH", "USDT"],
      amountToken0: null,
      amountToken1: null,
      tokenBalText: [0, 0],
      tokenReserves: [0, 0],
      insufficientBal: false,
      slippage: 15,
      deadline: 10,
      convertRate: 0,
      insuffLiq: false,
      dispPriceImp: false,
      priceImpVal: null,
      priceImpValBack: null,
      WrapUnwrap: null,
    };
  },
  mutations: mutations,
  actions: actions,
  getters: getters,
};
