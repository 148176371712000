<template>
  <BaseDialog
    :showModal="this.$store.state.showAccDialog"
    @clicked="closeAccountDetails"
  >
    <show-acc-details />
  </BaseDialog>
  <header class="app-header">
    <div class="app-header-left">
      <router-link to="/">
        <img
          v-if="isDark"
          src="../../assets/images/HeaderLogo.png"
          alt="SwapWave"
          class="app-header-logo"
        />
        <img
          v-else
          src="logo-dark.png"
          alt="SwapWave"
          class="app-header-logo"
        />
      </router-link>
    </div>
    <div class="app-header-center">
      <router-link to="/" class="app-header-navbar-item">
        Swap <span class="app-header-navbar-tag">comming soon</span>
      </router-link>
      <router-link to="/" class="app-header-navbar-item">
        Pool <span class="app-header-navbar-tag">comming soon</span>
      </router-link>
      <router-link to="/" class="app-header-navbar-item">
        Farm <span class="app-header-navbar-tag">comming soon</span>
      </router-link>
      <!-- <a
        href="https://info.uniswap.org/#/"
        target="_blank"
        class="app-header-navbar-item"
      >
        Charts&nbsp;↗
      </a> -->
    </div>
    <!-- <div class="app-header-right">
      <WalletConnect />
      <button @click="this.$router.push('/swap')">Launch App</button>
    </div> -->
  </header>
</template>

<script>
import { mapGetters } from "vuex";
import { ethers } from "ethers";
import { shortenAddress, displayChainName } from "vue-dapp";
const { ethereum } = window;

export default {
  setup() {
    return { shortenAddress, displayChainName };
  },
  data() {
    return {
      isDark: window?.matchMedia("(prefers-color-scheme: dark)").matches,
    };
  },
  methods: {
    closeAccountDetails() {
      this.$store.state.showAccDialog = false;
    },
    async changeNetwork(chainId) {
      const hex_chainId = ethers.utils.hexValue(chainId);
      await ethereum.request({
        method: "wallet_switchEthereumChain",
        params: [{ chainId: hex_chainId }],
      });
    },
  },
  computed: {
    ...mapGetters({
      displayWalletStatus: "displayWalletStatus",
    }),
  },
};
</script>

<style>
.app-header {
  display: flex;
  padding: 0.8rem 3.4rem 1rem 0.8rem;
  justify-content: space-between;
  align-items: center;
  width: var(--page-width);
  max-width: 100%;
  margin: 0 auto;
}

.app-header-logo {
  font-size: 32px;
  margin: 0 1rem 0 0;
  color: var(--primary-color);
  font-weight: 900;
}

.app-header-left,
.app-header-center,
.app-header-right {
  display: flex;
  /* flex: 1; */
  gap: 1rem;
  align-items: center;
}

.app-header-center {
  flex: 1;
  justify-content: flex-end;
  padding: 0 2.7rem;
}

.app-header-right {
  justify-content: flex-end;
}

/* .app-header-right button {
  width: 190px;
  height: 60px;
  border-radius: 50px;
} */

.app-header-navbar-item {
  padding: 0.5rem 1rem;
  font-size: 20px;
}

.app-header-navbar-tag {
  border: var(--element-border);
  color: var(--primary-color);
  font-size: 8px;
  display: inline-block;
  width: 47px;
  text-align: center;
  border-radius: 4px;
  margin-left: 8px;
  vertical-align: middle;
}

@media screen and (max-width: 800px) {
  .app-header-center {
    display: none;
  }
}
</style>
