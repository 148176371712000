<template>
  <div class="home-text">
    <p>
      SWAP WAVE is a next-generation layer-2 decentralized exchange and
      Automated Market Maker.
    </p>
  </div>
</template>

<style>
.home-text {
  padding: 92px 0;
}
.home-text p {
  font-size: 28px;
  line-height: 43px;
  width: 800px;
  margin: 0 auto;
  text-align: center;
  max-width: 100%;
}
</style>
