<template>
  <div class="home-community">
    <div class="home-community-subtitle">Become a part of our</div>
    <div class="home-community-title">Thriving Community.</div>
    <div class="social-wrapper">
      <img src="../../assets/images/social/twitter.svg" alt="" />
      <img src="../../assets/images/social/telegram.svg" alt="" />
      <img src="../../assets/images/social/discord.svg" alt="" />
      <img src="../../assets/images/social/medium.svg" alt="" />
      <img src="../../assets/images/social/youtube.svg" alt="" />
      <img src="../../assets/images/social/social.svg" alt="" />
    </div>
  </div>
</template>

<style>
.home-community {
  text-align: center;
  padding: 70px 0 0;
}
.home-community-subtitle {
  font-weight: 700;
  font-size: 34.3177px;
  line-height: 43px;
  color: #fff;
}
.home-community-title {
  font-weight: 700;
  font-size: 60px;
  background: linear-gradient(
    91.76deg,
    #ff00ff 64.95%,
    rgba(0, 0, 0, 0) 115.38%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}
.social-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 50px;
  margin: 50px 0 20px;
}
</style>
