<template>
  <div class="home-banner">
    <h3 class="home-banner-title title1">Decentralized</h3>
    <h3 class="home-banner-title title2">swap exchange</h3>
    <p class="home-banner-text">
      SwapWave is an EVM blockchain crafted for developers building games with
      player-owned economies.
    </p>
    <button class="home-banner-btn">Comming Soon</button>
    <!-- <button @click="this.$router.push('/swap')" class="home-banner-btn">
      Start Swaping Now
    </button> -->
  </div>
</template>

<style>
.home-banner {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 48px 0;
  height: calc(100vh - 200px);
}
.home-banner-title {
  font-weight: 700;
  font-size: 52.1975px;
  line-height: 78px;
  margin: 0;
  color: #ffffff;
  text-transform: uppercase;
}
.home-banner-title.title1 {
  background: linear-gradient(
    90deg,
    #e85bff 28.8%,
    rgba(232, 91, 255, 0.680159) 52.12%,
    rgba(232, 91, 255, 0) 101.71%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}
.home-banner-title.title2 {
  margin-top: -20px;
}
.home-banner-text {
  margin: 10px 0 40px;
  font-size: 20px;
  line-height: 24px;
  width: 550px;
  text-align: center;
  max-width: 100%;
}
.home-banner-btn {
  height: 48px;
  width: 228px;
}
</style>
