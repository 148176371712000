<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20px"
    height="20px"
    version="1.1"
    viewBox="0 0 650 650"
  >
    <g>
      <path
        d="m583.33 367.5c-7.7383-0.015625-15.164 3.0586-20.625 8.5391l-66.875 66.875v-279.58c0-10.418-5.5586-20.047-14.582-25.258-9.0234-5.2109-20.141-5.2109-29.168 0-9.0234 5.2109-14.582 14.84-14.582 25.258v279.58l-66.875-66.875c-7.3672-7.3672-18.109-10.246-28.176-7.5508-10.066 2.6992-17.93 10.562-20.625 20.629-2.6992 10.066 0.17969 20.809 7.5508 28.176l116.67 116.67c0.69922 0.67578 1.6094 0.95703 2.332 1.5625 2.0898 1.8906 4.4492 3.4648 7 4.668 4.8438 2.2461 10.25 3 15.523 2.168 5.2773-0.82812 10.188-3.2109 14.109-6.8359 0.69922-0.58203 1.6094-0.86328 2.332-1.5156l116.67-116.67c5.4961-5.4688 8.5859-12.906 8.5898-20.66 0.003906-7.7539-3.0781-15.191-8.5703-20.668-5.4922-5.4766-12.938-8.5391-20.695-8.5117z"
      />
      <path
        d="m137.29 183.96 66.875-66.875v279.58c0 10.418 5.5586 20.047 14.582 25.258 9.0234 5.2109 20.141 5.2109 29.168 0 9.0234-5.2109 14.582-14.84 14.582-25.258v-279.58l66.875 66.875c7.3672 7.3672 18.109 10.246 28.176 7.5508 10.066-2.6992 17.93-10.562 20.625-20.629 2.6992-10.066-0.17969-20.809-7.5508-28.176l-116.67-116.67c-0.65234-0.62891-1.5156-0.91016-2.332-1.4688-2.1289-1.9062-4.5352-3.4805-7.1367-4.668-7.207-3.0469-15.336-3.0469-22.543 0-2.5508 1.2031-4.9102 2.7734-7 4.668-0.69922 0.58203-1.5859 0.86328-2.332 1.5156l-116.67 116.67c-7.3594 7.3867-10.223 18.137-7.5039 28.203 2.7148 10.07 10.594 17.922 20.672 20.605 10.078 2.6797 20.82-0.21484 28.18-7.6016z"
      />
    </g>
  </svg>
</template>
