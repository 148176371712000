<template>
  <div class="app-dialog-wrapper" v-if="showModal">
    <dialog open>
      <slot />
    </dialog>
    <div class="app-dialog-backdrop" @click="onClickBackdrop"></div>
  </div>
</template>

<script>
export default {
  props: {
    showModal: Boolean,
  },
  methods: {
    onClickBackdrop(event) {
      this.$emit("clicked", false);
    },
  },
};
</script>

<style>
dialog {
  position: fixed;
  top: 2rem;
  z-index: 10;
  background-color: var(--element-background-color);
  border: var(--element-border);
  border-radius: var(--element-border-radius);
  width: calc(var(--card-element-width) + 1rem);
  max-height: calc(100vh - 4rem);
  overflow-y: auto;
  padding: 1rem;
  display: flex;
  flex-direction: column;
}
.app-dialog-wrapper {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 9;
}
.app-dialog-backdrop {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: var(--background-backdrop);
  z-index: 9;
}
.app-dialog-header {
  margin: 0 -1rem;
  padding: 0 1rem 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: var(--element-border);
}
.app-dialog-body {
  margin: 0 -1rem;
  padding: 1rem;
  flex: 1;
  overflow: auto;
}
.app-dialog-close {
  font-size: 24px;
  cursor: pointer;
}
</style>
