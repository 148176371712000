<template>
  <div class="liquidity">
    <div>
      <b>Liquidity burned:</b>
      <div>
        <p>
          Amount of {{ symbolVal[0] }} to be recieved :
          {{ RemLiqPredictedVal[1] }}
        </p>
        <p>
          Amount of {{ symbolVal[1] }} to be recieved :
          {{ RemLiqPredictedVal[2] }}
        </p>
        <p>Liquidity decrement : {{ RemLiqPredictedVal[0] }}</p>
      </div>
    </div>
    <div>
      <b>Your Balances :</b>
      <div>
        <p>
          <span>{{ symbolVal[0] }}: </span>
          {{ remLiqTokenBalVal[0] }}
        </p>
        <p>
          <span>{{ symbolVal[1] }}: </span>
          {{ remLiqTokenBalVal[1] }}
        </p>
      </div>

      <div>
        Your share of LP Tokens in pool:
        <span>{{ $store.state.remLiquidity.pairLiquidityPer }}%</span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      remLiqTokenBalVal: "getRemLiqTokenBal",
      symbolVal: "getSymbol",
      RemLiqPredictedVal: "getRemLiqPredictedVal",
    }),
  },
};
</script>

<style scoped>
.liquidity {
  font-weight: 300;
  border-top: var(--element-border);
  padding-top: 2rem;
  margin-top: 2rem;
}
.liquidity p {
  margin: 0;
  /* font-weight: 200; */
}
@media screen and (min-width: 700px) {
  .liquidity {
    display: flex;
    align-items: flex-start;
    justify-content: space-around;
  }
}
@media screen and (max-width: 700px) {
  .liquidity {
    display: flex;
    align-items: flex-start;
    justify-content: space-around;
    flex-wrap: wrap;
  }
}
</style>
