<template>
  <div class="app-page-wrapper remove-liquidity">
    <div class="app-card swap-form-card">
      <div class="app-card-header">
        <div class="app-hedaer-title">
          <router-link to="/pool">
            <svg height="24" viewBox="0 96 960 960" width="24">
              <path
                d="M400 948.308 27.692 576 400 203.692l42.539 42.539L112.769 576l329.77 329.769L400 948.308Z"
              />
            </svg>
          </router-link>
          <span>
            Remove Liquidity {{ symbolVal[0] }} - {{ symbolVal[1] }} LP
          </span>
        </div>
        <div class="app-header-action">
          <base-gear></base-gear>
        </div>
      </div>
      <div class="app-card-body">
        <div class="swap-form">
          <!-- <p style="margin-top: 0">
            {{ symbolVal[0] }} - {{ symbolVal[1] }} LP
          </p> -->
          <div class="app-input-suffix">
            <small class="max-btn-wrapper" v-if="displayWalletStatus">
              Balance: {{ $store.state.remLiquidity.pairLiquidity }}
              <span class="max-btn" @click="fillInputWithMaxAmt()">MAX</span>
            </small>
            <input
              type="number"
              placeholder="0.0"
              step="any"
              min="0"
              name="token0"
              id="token0"
              v-model.trim="$store.state.remLiquidity.pairLiqInp"
            />
          </div>
          <base-range-slider></base-range-slider>
        </div>
      </div>
      <div class="app-card-footer">
        <div v-if="$store.state.remLiquidity.insufficientRemLiqBal">
          <button
            :disabled="$store.state.remLiquidity.insufficientRemLiqBal"
            :class="{
              'button-disabled':
                $store.state.remLiquidity.insufficientRemLiqBal,
              'swap-button': true,
            }"
          >
            Insufficient {{ symbolVal[0] }}-{{ symbolVal[1] }} LP Balance
          </button>
        </div>
        <div v-else-if="!remLiqActive">
          <button class="swap-button">Enter Amount</button>
        </div>
        <div v-else class="flex-gap">
          <div v-if="$store.state.tokenApprovalInProcess">
            <button
              :disabled="$store.state.operationUnderProcess"
              :class="{
                'button-disabled': $store.state.operationUnderProcess,
                'swap-button': true,
              }"
              @click="approveRemLiq()"
            >
              Approve SwapWave to use {{ symbolVal[0] }}-{{ symbolVal[1] }} LP
            </button>
          </div>
          <button
            :disabled="
              $store.state.operationUnderProcess ||
              $store.state.tokenApprovalInProcess
            "
            :class="{
              'button-disabled':
                $store.state.operationUnderProcess ||
                $store.state.tokenApprovalInProcess,
              'swap-button': true,
            }"
            @click="remLiquidity()"
          >
            Remove Liquidity
          </button>
        </div>
      </div>
    </div>
  </div>
  <bal-res-section></bal-res-section>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import BalResSection from "../components/layout/BalResSecRemLiq.vue";
import { defineAsyncComponent } from "vue";
import swal from "sweetalert";

const BaseRangeSlider = defineAsyncComponent(() =>
  import("../components/UI/BaseRangeSlider.vue")
);

export default {
  components: { BalResSection, BaseRangeSlider },
  data() {
    return {
      remLiqActive: false,
    };
  },
  methods: {
    ...mapActions({
      approveRemLiq: "approveRemLiq",
      remLiquidity: "removeLiquidity",
      checkForBalDispPV: "checkMaxRemLiqBalDispPV",
    }),

    fillInputWithMaxAmt() {
      this.$store.state.remLiquidity.pairLiqInp =
        this.$store.state.remLiquidity.pairLiquidity;
    },
  },
  computed: {
    ...mapGetters({
      displayWalletStatus: "displayWalletStatus",
      symbolVal: "getSymbol",
    }),
  },
  watch: {
    "$store.state.remLiquidity.pairLiqInp"(newVal) {
      if (newVal != null) {
        this.checkForBalDispPV();
        if (this.$store.state.remLiquidity.pairLiqInp) {
          this.remLiqActive = true;
        } else {
          this.remLiqActive = false;
        }
      } else {
        this.remLiqActive = false;
      }
    },
    "$store.state.remLiquidity.pairLiquidity"(newVal) {
      if (newVal < 1e-12) {
        swal(
          "Info",
          "Since you have removed the full share of Liquidity you added, you can no longer remove LP Tokens from this pool! Hence you are being redirected to the pools page!",
          "info"
        );
        this.$router.push("/pool");
      }
    },
  },
  beforeRouteLeave(_, _2, next) {
    if (this.$store.state.canLeave == true) {
      this.$store.commit("resetRemLiqState");
      next();
    } else {
      next(false);
      swal("Alert", "Please wait for the transaction to end!", "warning");
    }
  },
};
</script>

<style scoped>
.app-input-suffix input {
  padding-right: 1rem;
}
</style>
