<template>
  <div class="home-features">
    <h1 class="home-features-title">
      Exceptional
      <span class="title2">Features</span>
    </h1>
    <div class="home-features-list">
      <div
        v-for="feature in features"
        :key="feature.key"
        class="app-card home-feature-card"
      >
        <div class="app-card-body">
          <div class="feature-img">
            <img :src="feature.icon" alt="" />
          </div>
          <div>
            <div class="feature-heading">
              {{ feature.heading }}
            </div>
            <div class="feature-text">
              {{ feature.text }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Feature1 from "../../assets/images/feature/f1.png";
import Feature2 from "../../assets/images/feature/f2.png";
import Feature3 from "../../assets/images/feature/f3.png";
import Feature4 from "../../assets/images/feature/f4.png";

const features = [
  {
    key: 1,
    icon: Feature1,
    heading: "Swap Tokens",
    text: "Trade any combination of ERC-20 tokens permissionless, with ease",
  },
  {
    key: 2,
    icon: Feature2,
    heading: "Supply Liquidity",
    text: "Earn fee on trades proportional to your share of the pool",
  },
  {
    key: 3,
    icon: Feature3,
    heading: "Earn Dwave",
    text: "Deposit your LP tokens to earn additional rewards",
  },
  {
    key: 4,
    icon: Feature4,
    heading: "Analytics",
    text: "Trade any combination of ERC-20 tokens permissionless, with ease",
  },
];

export default {
  data() {
    return {
      features,
    };
  },
};
</script>

<style>
.home-features {
  padding: 134px 0;
}
.home-features-title {
  font-size: 60px;
  line-height: 77px;
  color: #fff;
  margin: 0;
  text-transform: capitalize;
  text-align: center;
  letter-spacing: 3px;
}
.home-features-title .title2 {
  background: linear-gradient(
    90deg,
    #e85bff 28.8%,
    rgba(232, 91, 255, 0.680159) 52.12%,
    rgba(232, 91, 255, 0) 101.71%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}
.home-features-list {
  display: flex;
  flex-wrap: wrap;
  gap: 50px;
  margin-top: 100px;
}
.home-feature-card.app-card {
  flex: calc(50% - 100px);
  background-color: rgba(255, 0, 255, 0.1);
}
.home-feature-card .app-card-body {
  height: 190px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 27px;
}
.feature-heading {
  font-size: 22px;
  line-height: 28px;
  color: #fff;
  font-weight: 700;
  margin-bottom: 16px;
}
.feature-img {
  width: 88px;
  background: rgba(255, 0, 255, 0.1);
  border: 1px solid rgba(255, 0, 255, 0.25);
  box-shadow: 0px 3.65264px 3.65264px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  padding: 10px;
}
</style>
