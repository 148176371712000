<template>
  <div class="home-about">
    <div class="home-about-left">
      <h1 class="home-about-title title1">Swap Token</h1>
      <h1 class="home-about-title title2">Like a wave</h1>
      <p class="home-about-text">
        SwapWave DEX Aggregator connects liquidity across 9 blockchains and
        compares pricing from numerous Aggregators (1inch, Matcha) & DEXs
        (Uniswap, Sushiswap, Pangolin) to give you the best cross-chain swap in
        one click.
      </p>
    </div>
    <div class="home-about-right">
      <img src="../../assets/images/HomeAbout.png" />
    </div>
  </div>
</template>

<style>
.home-about {
  display: flex;
  gap: 130px;
}
.home-about-left {
  padding: 32px 0;
}
.home-about-title {
  font-size: 66.25px;
  line-height: 83px;
  color: #fff;
  margin: 0;
  text-transform: capitalize;
}
.home-about-title.title2 {
  background: linear-gradient(
    90deg,
    #e85bff 28.8%,
    rgba(232, 91, 255, 0.680159) 52.12%,
    rgba(232, 91, 255, 0) 101.71%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}
.home-about-text {
  font-size: 22px;
  line-height: 32px;
  margin-top: 14px;
  text-align: justify;
}
</style>
