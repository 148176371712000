<template>
  <div class="app-dropdown">
    <div class="app-dropdown-trigger" @click="showOrHideDropdown()">
      <gearSvg></gearSvg>
    </div>
    <div class="app-dropdown-content" :style="{ display: displayDropdown }">
      <!-- <p>Transaction Settings:</p> -->
      <div class="app-form">
        <div class="app-form-item">
          <label for="slippage"> Slippage Tolerance (%): </label>
          <input
            type="percentage"
            placeholder="Default: 15%"
            step="1"
            min="0"
            name="slippage"
            id="slippage"
            v-model.trim="slippage"
          />
        </div>
        <div class="app-form-item">
          <label for="deadline"> Transaction Deadline (min): </label>
          <input
            type="number"
            placeholder="Default: 10"
            step="1"
            min="0"
            name="deadline"
            id="deadline"
            v-model.trim="deadline"
          />
        </div>
        <div class="app-form-item">
          <button @click="submitInp()">OK</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import gearSvg from "../../assets/svg/gear.vue";
export default {
  components: { gearSvg },
  data() {
    return {
      displayDropdown: "none",
      slippage: 15,
      deadline: 10,
    };
  },
  methods: {
    showOrHideDropdown() {
      if (this.displayDropdown === "none") {
        this.displayDropdown = "block";
      } else {
        this.displayDropdown = "none";
      }
    },
    submitInp() {
      if (this.$route.path === "/swap") {
        this.$store.state.swap.slippage = this.slippage;
        this.$store.state.swap.deadline = this.deadline;
      } else {
        if (this.$route.path.search("addLiquidity") === 1) {
          this.$store.state.addLiquidity.slippageAddLiq = this.slippage;
          this.$store.state.addLiquidity.deadlineAddLiq = this.deadline;
        } else {
          if (this.$route.path.search("removeLiquidity") === 1) {
            this.$store.state.remLiquidity.slippageRemLiq = this.slippage;
            this.$store.state.remLiquidity.deadlineRemLiq = this.deadline;
          }
        }
      }
      this.showOrHideDropdown();
    },
  },
};
</script>

<style scoped>
.app-dropdown-content {
  width: 300px;
  padding: 1.5rem;
}
</style>
