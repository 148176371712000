<template>
  <div class="home">
    <HomeBanner />
    <HomeText />
    <HomeAbout />
    <HomeFeature />
    <HomeCommunity />
  </div>
</template>

<script>
import HomeBanner from "../components/Home/HomeBanner.vue";
import HomeText from "../components/Home/HomeText.vue";
import HomeAbout from "../components/Home/HomeAbout.vue";
import HomeFeature from "../components/Home/HomeFeature.vue";
import HomeCommunity from "../components/Home/HomeCommunity.vue";

export default {
  components: {
    HomeBanner,
    HomeText,
    HomeAbout,
    HomeFeature,
    HomeCommunity,
  },
};
</script>

<style>
.home {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
}
.home > div {
  width: 100%;
}
.home-features {
  position: relative;
}
.home-features::before {
  content: "";
  position: absolute;
  top: -25%;
  width: 100%;
  height: 100%;
  background: url("../assets/images/Gradient.png");
  z-index: -1;
}
</style>
