// import web3 from "../../ethereum/web3";
import * as chains from "./chains";
// import * as ethFunc from "../ethereumFunctions";

// const ERC20 = require("../../ethereum/.deps/npm/@rari-capital/solmate/src/tokens/artifacts/ERC20.json");
// const account = "0xb677263DDab7f3d9C60d7c5168eeD79423E6611A";

export let GÖRLICoins = [
  {
    name: "Wrapped Ether",
    abbr: "WETH",
    address: "0x82aF49447D8a07e3bd95BD0d56f35241523fBab1",
    balance: 0,
    addedByUser: false,
    marker: false,
  },
  {
    name: "Tether USD",
    abbr: "USDT",
    address: "0xFd086bC7CD5C481DCC9C85ebE478A1C0b69FCbb9",
    balance: 0,
    addedByUser: false,
    marker: false,
  },
  {
    name: "Arbitrum",
    abbr: "ARB",
    address: "0x912CE59144191C1204E64559FE8253a0e49E6548",
    balance: 0,
    addedByUser: false,
    marker: false,
  },
];

const COINS = new Map();
// COINS.set(chains.ChainId.MAINNET, MAINNETCoins);
// COINS.set(chains.ChainId.ROPSTEN, ROPSTENCoins);
// COINS.set(chains.ChainId.RINKEBY, RINKEBYCoins);
COINS.set(chains.ChainId.ARBITRUM, GÖRLICoins);
// COINS.set(chains.ChainId.KOVAN, KOVANCoins);
export default COINS;
